import React, { useEffect, useState } from 'react';
import SideNav from './SideNav';
import axios from 'axios';
import '../Css/AdminOrders.css'

export default function AdminOrders() {
    const [orders, setOrders] = useState([]);
    const [allOrders, setAllOrders] = useState([]); // To keep all orders fetched
    const [filter, setFilter] = useState({ username: '', orderType: '' });

    const fetchAllOrders = async () => {
        try {
            const { data } = await axios.get('https://free.jeevansanskriti.com/api/v1/order', {
                params: {
                    username: filter.username
                }
            });
            const arrayOfOrders = data.data;
            setAllOrders(arrayOfOrders.map((order) => ({
                id: order.id,
                order_id: order.order_id,
                productIds: order.productIds,
                message: order.message,
                quantities:order.quantities,
                user_id: order.user_id,
                total_amount: order.total_amount,
                payment_mode: order.payment_mode,
                payment_status: order.payment_status,
                bill_firstName: order.bill_firstName,
                bill_lastName: order.bill_lastName,
                bill_mobile: order.bill_mobile,
                bill_address: order.bill_address,
                bill_pincode: order.bill_pincode,
                bill_city: order.bill_city,
                bill_state: order.bill_state,
                bill_email: order.bill_email,
                orderTypes: order.orderTypes,
                gst_no: order.gst_no,
                product_names: order.product_names,
                created_at: order.created_at
            })));
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    useEffect(() => {
        fetchAllOrders();
    }, []); // Fetch once on component mount

    useEffect(() => {
        const filteredOrders = allOrders.filter((order) => {
            return (!filter.orderType || order.orderTypes === filter.orderType);
        });
        setOrders(filteredOrders);
    }, [filter, allOrders]); // Re-filter when filter or all orders change

    const handleSortByDate = () => {
        const sortedOrders = [...orders].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setOrders(sortedOrders);
    };

    const handleFilterChange = (e) => {
        setFilter({ ...filter, [e.target.name]: e.target.value });
    };

    return (
        <>
            <div className="admin-panel-container">
                <SideNav />
                <div className="content-container">
                    <h1 className="page-title">Orders</h1>

                    <div className="filter-container">
                        <input
                            type="text"
                            name="username"
                            value={filter.username}
                            onChange={handleFilterChange}
                            placeholder="Search by Username"
                        />
                        <select
                            name="orderType"
                            value={filter.orderType}
                            onChange={handleFilterChange}
                        >
                            <option value="">All Orders</option>
                            <option value="pooja">Pooja</option>
                            <option value="product">Product</option>
                        </select>
                        <button onClick={handleSortByDate}>Sort By Date</button>
                    </div>

                    <div className="table-container">
                        <table className="user-table">
                            <thead>
                                <tr>
                                    <th>SR. No</th>
                                    <th>Order Id</th>
                                    <th>Order Type</th>
                                    <th>Message</th>
                                    
                                    <th>Product Id</th>
                                    <th>Quantity</th>
                                    <th>User Id</th>
                                    <th>Total Amount</th>
                                    <th>Payment Mode</th>
                                    <th>Payment Status</th>
                                    <th>Products</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Phone Number</th>
                                    <th>Address</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Pincode</th>
                                    <th>Email</th>
                                    <th>Gst</th>
                                    <th>CREATED</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((order, index) => (
                                    <tr key={order.id}>
                                        <td>{index + 1}</td>
                                        <td>{order.order_id}</td>
                                        <td>{order.orderTypes}</td>
                                        <td>{order.message}</td>
                                        <td>{order.productIds}</td>
                                        <td>{order.quantities}</td>
                                        <td>{order.user_id}</td>
                                        <td>{order.total_amount}</td>
                                        <td>{order.payment_mode}</td>
                                        <td>{order.payment_status}</td>
                                        <td>{order.product_names}</td>
                                        <td>{order.bill_firstName}</td>
                                        <td>{order.bill_lastName}</td>
                                        <td>{order.bill_mobile}</td>
                                        <td>{order.bill_address}</td>
                                        <td>{order.bill_city}</td>
                                        <td>{order.bill_state}</td>
                                        <td>{order.bill_pincode}</td>
                                        <td>{order.bill_email}</td>
                                        {order.gst_no ? <td>{order.gst_no}</td> : <td>Null</td>}
                                        <td>{order.created_at}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
