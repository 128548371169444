import React, { useState, useEffect, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { CgShoppingBag } from "react-icons/cg";
import { BsCart2 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import "../Css/Header.css";
import axios from "axios";

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [cartItemCount, setCartItemCount] = useState(0); // State to track cart items

  const searchRef = useRef(null);
  const searchResultsRef = useRef(null);
  const drawerRef = useRef(null);
  const navigate = useNavigate();
  const localStorageData = JSON.parse(localStorage.getItem("data"));

  useEffect(() => {
    // Assuming you have a function to fetch cart items from API or localStorage
    fetchCartItemCount();
  }, []);

  const fetchCartItemCount = async () => {
    try {
      const response = await axios.get(`https://free.jeevansanskriti.com/api/v1/cart/total/${localStorageData.userId}`);
      const total = (response.data.data[0].total_product);
      
      const itemCount = Number(total) || 0;
      setCartItemCount(itemCount); // Update cart item count with a number
      console.log('Cart Item Count:', itemCount); // Log the count
    } catch (error) {
      console.error("Error fetching cart item count:", error);
      setCartItemCount(0); // Default to 0 on error
    }
  };
  
  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query) {
      fetchSearchResults(query);
    } else {
      setSearchResults([]);
    }
  };

  const fetchSearchResults = async (query) => {
    try {
      const { data } = await axios.get(
        `https://free.jeevansanskriti.com/api/v1/search?query=${query}`
      );
      setSearchResults(data.data);
    } catch (error) {
      console.error("Error fetching search results", error);
    }
  };

  const handleSearchResultClick = (result) => {
    const isProduct = result.hasOwnProperty("product_name");
    const id = isProduct ? result.id : result.id;
    const path = isProduct ? `/details/${id}` : `/booking/${id}`;
    navigate(path);
    setSearchResults([]); // Close search results after selecting
  };

  const handleClickOutside = (event) => {
    if (
      searchResults.length > 0 &&
      searchRef.current &&
      searchResultsRef.current &&
      !searchRef.current.contains(event.target) &&
      !searchResultsRef.current.contains(event.target)
    ) {
      setSearchResults([]); // Close search results if clicking outside
    }

    if (
      drawerOpen &&
      drawerRef.current &&
      !drawerRef.current.contains(event.target)
    ) {
      setDrawerOpen(false); // Close drawer if clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchResults, drawerOpen]);

  const isActive = (path) => window.location.pathname === path;

  return (
    <header className="header-root">
      <div className="app-bar">
        <div className="toolbar">
          <div className="logo">
            <img src="../jeevan-removebg.png" alt="Logo" />
            <div className="logo-title">Jeevansanskriti</div>
          </div>
          <nav className="nav-items">
            <div
              className={`nav-item ${isActive("/") ? "active" : ""}`}
              onClick={() => navigate("/")}
            >
              Home
            </div>
            <div
              className={`nav-item ${isActive("/store") ? "active" : ""}`}
              onClick={() => window.open("/store", "_blank")}
            >
              Store
            </div>
            <div
              className={`nav-item ${isActive("/services") ? "active" : ""}`}
              onClick={() => window.open("/services", "_blank")}
            >
              Catering
            </div>
            <div
              className={`nav-item ${isActive("/contact") ? "active" : ""}`}
              onClick={() => window.open("/contact", "_blank")}
            >
              Contact
            </div>
            {!localStorageData ? (
              <div
                className={`nav-item ${isActive("/login") ? "active" : ""}`}
                onClick={() => window.open("/login", "_blank")}
              >
                Login
              </div>
            ): 
            <div
            className={`nav-item ${isActive("/login") ? "active" : ""}`}
            onClick={() => window.open("/login", "_blank")}
          >
            {localStorageData.username}
          </div>
            }
          </nav>
          <div className="search" ref={searchRef}>
            <FaSearch className="search-icon" />
            <input
              type="text"
              placeholder="Search…"
              className="input-base"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
            {searchResults.length > 0 && (
              <div className="search-results" ref={searchResultsRef}>
                {searchResults.map((result, index) => (
                  <div
                    key={index}
                    className="search-result-item"
                    onClick={() => handleSearchResultClick(result)}
                  >
                    <img
                      src={`https://free.jeevansanskriti.com/images/${
                        result.main_image ? result.main_image : result.image
                      }`}
                      alt={result.product_name || result.pooja_name}
                    />
                    <span className="product-name">
                      {result.product_name || result.pooja_name}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="cart-icon-wrapper">
            <div className="icon-button" onClick={() => navigate("/cart")}>
              <BsCart2 />
              <span className="cart-item-count">{cartItemCount}</span>

              
            </div>
          </div>
          <div
            className="mobile-menu-button"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <div className={`burger-menu ${drawerOpen ? "open" : ""}`}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          </div>
        </div>
      </div>
      <div className={`drawer ${drawerOpen ? "open" : ""}`} ref={drawerRef}>
        <div className="drawer-content">
          <div
            className={`drawer-item ${isActive("/") ? "active" : ""}`}
            onClick={() => navigate("/")}
          >
            Home
          </div>
          <div
            className={`drawer-item ${isActive("/store") ? "active" : ""}`}
            onClick={() => window.open("/store", "_blank")}
          >
            Store
          </div>
          <div
            className={`drawer-item ${isActive("/services") ? "active" : ""}`}
            onClick={() => window.open("/services", "_blank")}
          >
            Catering
          </div>
          <div
            className={`drawer-item ${isActive("/contact") ? "active" : ""}`}
            onClick={() => window.open("/contact", "_blank")}
          >
            Contact
          </div>
          {!localStorageData && (
            <div
              className={`nav-item ${isActive("/login") ? "active" : ""}`}
              onClick={() => window.open("/login", "_blank")}
            >
              Login
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
