import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import '../Css/BookingPage.css';
import Header from './Header';
import Footer from './Footer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import axios from 'axios'; // Make sure axios is imported

export default function BookingPage() {
  const { id } = useParams();
  const [selectedPackage, setSelectedPackage] = useState('Starter');
  const [selectedCity, setSelectedCity] = useState('Delhi NCR');
  const [poojaImage, setPoojaImage] = useState('');
  const [poojaMessage, setPoojaMessage] = useState('');
  const [poojaName, setPoojaName] = useState('');
  const [features, setFeatures] = useState([]);
  const [startFeatures, setStartFeatures] = useState([]);
  const [standardFeatures, setStandardFeatures] = useState([]);
  const [premiumFeatures, setPremiumFeatures] = useState([]);
  const [packagePrices, setPackagePrices] = useState({
    Starter: '₹5000',
    Standard: '₹8000',
    Premium: '₹12000'
  });
  const [poojaDate, setPoojaDate] = useState(null); // State to store selected date

  const navigate = useNavigate();

  const fetchPooja = async () => {
    try {
      const { data } = await axios.get(`https://free.jeevansanskriti.com/api/v1/pooja/${id}`);
      const poojaData = data.data[0];
      
      setPoojaImage(poojaData.image);
      setPoojaMessage(poojaData.message);
      setPoojaName(poojaData.pooja_name);
      setFeatures(poojaData.features.split(","));

      const starterTier = poojaData.price_tiers.find(tier => tier.tier_name === "Starter");
      const standardTier = poojaData.price_tiers.find(tier => tier.tier_name === "Standard");
      const premiumTier = poojaData.price_tiers.find(tier => tier.tier_name === "Premium");

      setPackagePrices({
        Starter: starterTier ? starterTier.price : "Price not available",
        Standard: standardTier ? standardTier.price : "Price not available",
        Premium: premiumTier ? premiumTier.price : "Price not available"
      });

      setStartFeatures(starterTier ? starterTier.features.split(",") : ['No features available']);
      setStandardFeatures(standardTier ? standardTier.features.split(",") : ['No features available']);
      setPremiumFeatures(premiumTier ? premiumTier.features.split(",") : ['No features available']);
    } catch (error) {
      console.error("Error fetching pooja data:", error);
    }
  };

  useEffect(() => {
    fetchPooja();
  }, [id]);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handlePackageClick = (packageName) => {
    setSelectedPackage(packageName);
  };

  const handleCityClick = (cityName) => {
    setSelectedCity(cityName);
  };

  const handleBookNow = () => {
    if (poojaDate) {
      const formattedDate = format(poojaDate, 'dd-MM-yyyy');
    }
    navigate('/puja-final_checkout', {
      state: {
        poojaName,
        productId: id,
        selectedPackage,
        packagePrice: packagePrices[selectedPackage],
        selectedCity,
        poojaImage,
        poojaDate
      }
    });
  };

  return (
    <>
      <Header />
      <div className="containerTR">
        <div className="contentTR">
          <div className="imageContainerTR">
            <img 
              src={`https://free.jeevansanskriti.com/images/${poojaImage}`}
              alt="Vastu Shanti Puja"
              className="pujaImageTR"
            />
          </div>
          <div className="detailsTR">
            <h1 className="titleTR">{poojaName}</h1>
            <p className="mb4TR">{poojaMessage}</p>
          </div>
        </div>
        <div className="bookingTR">
          <h2 className="subtitleTR">Book this Pooja</h2>
          <h3 className="subsubtitleTR">{poojaName}</h3>
          {features.map((fea, index) => (
            <div className="featureTR" key={index}>
              <span className="checkmarkTR">✔️</span> {fea}
            </div>
          ))}
          <div className="mb4TR">
            <label className="blockLabelTR">Select Package</label>
            <div className="buttonGroupTR">
              <button
                className={`buttonTR ${selectedPackage === 'Starter' ? 'selected' : ''}`}
                onClick={() => handlePackageClick('Starter')}
              >
                Starter
              </button>
              <button
                className={`buttonTR ${selectedPackage === 'Standard' ? 'selected' : ''}`}
                onClick={() => handlePackageClick('Standard')}
              >
                Standard
              </button>
              <button
                className={`buttonTR ${selectedPackage === 'Premium' ? 'selected' : ''}`}
                onClick={() => handlePackageClick('Premium')}
              >
                Premium
              </button>
            </div>
          </div>

          {selectedPackage && (
            <div className="packageDetailsTR">
              <h3 className="subsubtitleTR">{selectedPackage}</h3>
              {packagePrices[selectedPackage] === "Price not available" ? (
                <p className="priceDetailsTR">{packagePrices[selectedPackage]}</p>
              ) : (
                <p className="priceDetailsTR">Rs.{packagePrices[selectedPackage]}</p>
              )}
              <ul className="listTR">
                {selectedPackage === "Starter" ? (
                  startFeatures.map((item, index) => <li key={index}>{item}</li>)
                ) : selectedPackage === "Standard" ? (
                  standardFeatures.map((item, index) => <li key={index}>{item}</li>)
                ) : (
                  premiumFeatures.map((item, index) => <li key={index}>{item}</li>)
                )}
              </ul>
            </div>
          )}

          <div className="mb4TR">
            <label className="blockLabelTR">Select City</label>
            <div className="buttonGroupTR">
              <button
                className={`buttonTR ${selectedCity === 'Pune' ? 'selected' : ''}`}
                onClick={() => handleCityClick('Pune')}
              >
                Pune
              </button>
              <button
                className={`buttonTR ${selectedCity === 'Delhi NCR' ? 'selected' : ''}`}
                onClick={() => handleCityClick('Delhi NCR')}
              >
                Delhi NCR
              </button>
              <button
                className={`buttonTR ${selectedCity === 'Bangalore' ? 'selected' : ''}`}
                onClick={() => handleCityClick('Bangalore')}
              >
                Bangalore
              </button>
            </div>
          </div>

          {/* Date Picker for Selecting Pooja Date */}
          <div className="mb4TR">
            <label className="blockLabelTR">Select Date for Pooja</label>
            <DatePicker
              selected={poojaDate}
              onChange={(date) => setPoojaDate(date)}
              dateFormat="dd-MM-yyyy"
              placeholderText="Select date"
              className="datePickerTR"
            />
          </div>

          <button className="bookButtonTR" onClick={handleBookNow}>
            Book Now
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
