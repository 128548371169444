import React from "react";
import "../Css/Featuring.css";
import { Link, useNavigate } from "react-router-dom";

export default function Featuring() {
  const navigate = useNavigate();
  const handleRedirectToSamagri = (golaTo) => {
    navigate("/store", { state: { selectedCategory: golaTo } });
  };

  return (
    <div className="T-container">
      <h3 className="T-title">Bringing Divine Serenity to Your Home</h3>
      <div className="gole">
        <div className="gole1" onClick={() => handleRedirectToSamagri("Pooja Kit")}>
          <img
            className="gola-image"
            src="https://img.freepik.com/free-vector/realistic-background-tamil-new-year-celebration_23-2150189539.jpg?t=st=1722581236~exp=1722584836~hmac=1d7b98ac36e110e44484bed0503d3f1088cd24b59511d088dd6075e5e421cd37&w=996"
            alt=""
          />
          <h3 className="T-name">Puja Samagri</h3>
        </div>

        <div className="gole1" onClick={()=>handleRedirectToSamagri("Pooja")}>
          <img
            className="gola-image"
            src="https://www.pujanpujari.com/wp-content/uploads/2022/04/Lakshmi-Pooja-1-300x300.png"
            alt=""
          />
          <h3 className="T-name">Puja Booking</h3>
        </div>

        <div className="gole1" onClick={()=> navigate('/services')}>
          <img
            className="gola-image"
            src="https://cdn.siasat.com/wp-content/uploads/2023/11/hyderabadi-thali.jpg"
            alt=""
          />
          <h3 className="T-name">Catering</h3>
        </div>

        <div className="gole1" onClick={()=> window.location.href = "https://wa.me/6377692127"}>
          <img
            className="gola-image"
            src="https://imagesvs.oneindia.com/img/2024/01/horoscope-og-20240127230411.jpg"
            alt=""
          />
          <h3 className="T-name">Horoscope</h3>
        </div>

      </div>
    </div>
  );
}
