import React, { useState } from "react";
import SideNav from './SideNav';
import axios from "axios";

export default function AdminBlog() {
    const [blog, setBlog] = useState({
        title: "",
        blogs_image: null,
        short_description: "",
        description: "",
        author: "",
    });

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        visible: false
    });

    const handleBlogSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("title", blog.title);
            formData.append("blogs_image", blog.blogs_image); 
            formData.append("short_description", blog.short_description);
            formData.append("description", blog.description);
            formData.append("author", blog.author);

            // Log form data
            for (const pair of formData.entries()) {
                console.log(`${pair[0]}: ${pair[1]}`);
            }

            const response = await axios.post(
                "https://free.jeevansanskriti.com/api/v1/blogs",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            

            setAlert({
                type: 'success',
                message: 'Blog uploaded successfully!',
                visible: true
            });

            // Clear form fields after successful upload
            setBlog({
                title: "",
                blogs_image: null,
                short_description: "",
                description: "",
                author: "",
            });
        } catch (error) {
            if (error.response) {
                console.error("Error response:", error.response.data);
                setAlert({
                    type: 'danger',
                    message: `Failed to upload blog: ${error.response.data.message || 'Server error'}`,
                    visible: true
                });
            } else if (error.request) {
                console.error("Error request:", error.request);
                setAlert({
                    type: 'danger',
                    message: 'Failed to upload blog: No response from server.',
                    visible: true
                });
            } else {
                console.error("Error message:", error.message);
                setAlert({
                    type: 'danger',
                    message: `Failed to upload blog: ${error.message}`,
                    visible: true
                });
            }
            console.error("Error config:", error.config);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBlog({
            ...blog,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setBlog({
            ...blog,
            blogs_image: file,
        });
    };

    const dismissAlert = () => {
        setAlert({
            ...alert,
            visible: false
        });
    };

    return (
        <div className='admin-panel-container'>
            <SideNav />
            <div className='form-container'>
                {alert.visible && (
                    <div className={`alert alert-${alert.type}`}>
                        {alert.message}
                        <button type="button" className="close" onClick={dismissAlert}>
                            <span>&times;</span>
                        </button>
                    </div>
                )}
                <form onSubmit={handleBlogSubmit}>
                    <label>
                        Blog Title
                        <input
                            type="text"
                            name="title"
                            value={blog.title}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Blog Image
                        <input
                            type="file"
                            name="blogs_image"
                            onChange={handleFileChange}
                            required
                        />
                    </label>
                    <label>
                        Short Description
                        <input
                            type="text"
                            name="short_description"
                            value={blog.short_description}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Description
                        <input
                            type="text"
                            name="description"
                            value={blog.description}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Author
                        <input
                            type="text"
                            name="author"
                            value={blog.author}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <button type="submit">Upload Blog</button>
                </form>
            </div>
        </div>
    );
}
