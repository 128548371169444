import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../Css/Service.css';
import Header from './Header';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

export default function Service() {
    const location = useLocation();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        event_date: '',
        guests: '',
        additionalDetails: ''
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: value
        }));
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const params = {
            name: formData.name,
            email: formData.email,
            mobile: formData.phone,
            event_date: formData.event_date,
            guests: formData.guests,
            additional_details: formData.additionalDetails
        };

        try {
            const response = await axios.post('https://free.jeevansanskriti.com/api/v1/service', params);
            alert('Service request submitted successfully');
        } catch (error) {
            console.error('Error submitting the service request:', error);
            alert('Failed to submit service request');
        }
    };

    return (
        <>
            <Header />
            <div className="catering-services-container">
                <h1 className="catering-services-title">Catering Service Request</h1>
                <form className="catering-services-form" onSubmit={handleSubmit}>
                    <div className="catering-services-form-group">
                        <label htmlFor="name" className="catering-services-form-label">Name</label>
                        <input
                            type="text"
                            id="name"
                            className="catering-services-form-input"
                            placeholder="Enter your name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="catering-services-form-group">
                        <label htmlFor="email" className="catering-services-form-label">Email</label>
                        <input
                            type="email"
                            id="email"
                            className="catering-services-form-input"
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="catering-services-form-group">
                        <label htmlFor="phone" className="catering-services-form-label">Phone Number</label>
                        <input
                            type="tel"
                            id="phone"
                            className="catering-services-form-input"
                            placeholder="Enter your phone number"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="catering-services-form-group">
                        <label htmlFor="event_date" className="catering-services-form-label">Event Date</label>
                        <input
                            type="date"
                            id="event_date"
                            className="catering-services-form-input"
                            value={formData.event_date}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="catering-services-form-group">
                        <label htmlFor="guests" className="catering-services-form-label">Number of Guests</label>
                        <input
                            type="number"
                            id="guests"
                            className="catering-services-form-input"
                            placeholder="Enter number of guests"
                            value={formData.guests}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="catering-services-form-group">
                        <label htmlFor="additionalDetails" className="catering-services-form-label">Additional Details</label>
                        <textarea
                            id="additionalDetails"
                            className="catering-services-form-input"
                            rows="4"
                            placeholder="Enter any additional details"
                            value={formData.additionalDetails}
                            onChange={handleChange}
                        ></textarea>
                    </div>
                    <button type="submit" className="catering-services-submit-button">Submit Request</button>
                </form>
            </div>
            <Footer />
        </>
    );
}
