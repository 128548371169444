import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../Css/Store.css";
import axios from "axios";
import PoojaCard from "./PoojaCard";
import { useLocation } from "react-router-dom";

const truncateText = (text, length) => {
  if (text.length <= length) return text;
  return text.substring(0, length) + "...";
};

export default function Store() {
  const location = useLocation();
  const { state } = location;
  const initialSelectedCategory = state?.selectedCategory || "Pooja";
  const initialShowDiscounted = state?.showDiscounted || false;
  const [selectedCategory, setSelectedCategory] = useState(initialSelectedCategory);
  const [selectedPoojaCategory, setSelectedPoojaCategory] = useState(null);
  const [selectedSamagri, setSelectedSamagri] = useState([]);
  const [filteredSamagri, setFilteredSamagri] = useState([]);
  const [poojaCategory, setPoojaCategory] = useState([]);
  const [poojaCategoryDetails, setPoojaCategoryDetails] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [showDiscounted, setShowDiscounted] = useState(initialShowDiscounted); // Use state from location

  const storeCategories = {
    categories: [
      { name: "Pooja Kit", description: "Puja Samagri items" },
      { name: "Pooja", description: "Pooja Services" },
    ],
  };

  const fetchPoojaCategory = async () => {
    try {
      const { data } = await axios.get('https://free.jeevansanskriti.com/api/v1/pooja_category');
      setPoojaCategory(data.data);

      if (data.data.length > 0) {
        const defaultCategory = data.data[0];
        setSelectedPoojaCategory(defaultCategory.category_name);
        fetchPoojaCategoryDetails(defaultCategory.id);
      }
    } catch (error) {
      console.error("Error fetching Pooja categories:", error);
    }
  };

  const fetchPoojaCategoryDetails = async (id) => {
    try {
      const { data } = await axios.get(`https://free.jeevansanskriti.com/api/v1/pooja/category/${id}`);
      setPoojaCategoryDetails(data.data);
    } catch (error) {
      console.error("Error fetching Pooja category details:", error);
    }
  };

  const fetchSamagri = async () => {
    try {
      const { data } = await axios.get('https://free.jeevansanskriti.com/api/v1/product');
      const samagriWithImages = data.data.map((item) => ({
        ...item,
        imageURLs: [item.ImageURLs ? item.ImageURLs.split(",") : []],
      }));
      setSelectedSamagri(samagriWithImages);
    } catch (error) {
      console.error("Error fetching Samagri:", error);
    }
  };

  useEffect(() => {
    fetchPoojaCategory();
    fetchSamagri();
  }, []);

  const checkExpiration = () => {
    const storedData = JSON.parse(localStorage.getItem("data"));
    if (storedData) {
      const currentTime = Date.now();
      const thirtyMinutes = 30 * 60 * 1000;

      if (currentTime - storedData.loginTime > thirtyMinutes) {
        localStorage.removeItem("data");
        alert("Session expired. Please login again.");
      } else {
        axios.defaults.headers.common["Authorization"] = "Bearer " + storedData.token;
      }
    }
  };

  useEffect(() => {
    checkExpiration();
  }, []);

  useEffect(() => {
    let filteredItems = selectedSamagri;

    if (selectedCategory === "Pooja Kit" && selectedSubcategory) {
      filteredItems = selectedSamagri.filter(item => item.subcategory_id === selectedSubcategory);
    }

    if (showDiscounted) {
      filteredItems = filteredItems.filter(item => item.discount && item.discount > 0);
    }

    setFilteredSamagri(filteredItems);
  }, [selectedSubcategory, selectedSamagri, selectedCategory, showDiscounted]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handlePoojaCategoryClick = (category, id) => {
    setSelectedPoojaCategory(category);
    fetchPoojaCategoryDetails(id);
  };

  const handleAddToCart = (item) => {
    console.log("Add to cart:", item);
  };

  const toDetailsPage = (id) => {
    window.open(`/details/${id}`);
  };

  const handleSubcategoryChange = (subcategoryId) => {
    setSelectedSubcategory(subcategoryId);
  };

  return (
    <>
      <Header />
      <main>
        <div className="store-container">
          <div className="sidebar">
            {storeCategories.categories.map((category, index) => (
              <div
                key={index}
                className={`category-item ${selectedCategory === category.name ? "active" : ""}`}
                onClick={() => handleCategoryClick(category.name)}
              >
                <h3>{category.name}</h3>
                <p>{category.description}</p>
              </div>
            ))}
          </div>
          <div className="content">
            {selectedCategory && (
              <>
                <div className="content-header">
                  <h2>{selectedCategory}</h2>
                  {selectedCategory === "Pooja Kit" && (
                    <div className="subcategory-filters">
                      <button
                        className={selectedSubcategory === 1 ? "active" : ""}
                        onClick={() => handleSubcategoryChange(1)}
                      >
                        Puja Kit
                      </button>
                      <button
                        className={selectedSubcategory === 2 ? "active" : ""}
                        onClick={() => handleSubcategoryChange(2)}
                      >
                        Samgri
                      </button>
                      <button
                        className={showDiscounted ? "active" : ""}
                        onClick={() => setShowDiscounted(prev => !prev)}
                      >
                        Show Discounted
                      </button>
                    </div>
                  )}
                </div>
                {selectedCategory === "Pooja" && poojaCategory.length > 0 && (
                  <div className="pooja-category-grid">
                    {poojaCategory.map((category, index) => (
                      <div
                        className={`pooja-category-item ${selectedPoojaCategory === category.category_name ? "active" : ""}`}
                        key={index}
                        onClick={() => handlePoojaCategoryClick(category.category_name, category.id)}
                      >
                        <h3>{category.category_name}</h3>
                      </div>
                    ))}
                  </div>
                )}
                {selectedCategory === "Pooja Kit" && filteredSamagri.length > 0 && (
                  <div className="samagri-grid">
                    {filteredSamagri.map((item) => (
                      <div className="samagri-item" key={item.id} onClick={() => toDetailsPage(item.id)}>
                        {item.imageURLs.length > 0 && (
                          <img src={`https://free.jeevansanskriti.com/images/${item.main_image}`} alt={item.product_name} />
                        )}
                        <h3 className="PUJA-product-name">{item.product_name}</h3>
                        {/* <p className="PUJA-product-description">
                          {truncateText(item.product_description, 50)}
                        </p> */}
                        <p>
                          Rs.{Number(item.price).toFixed(2)}{" "}
                          {item.discount > 0 && (
                            <span className="discounted-price">
                              Rs.{(item.price - item.price * (item.discount / 100)).toFixed(2)}
                            </span>
                          )}
                        </p>
                        <button className="add-to-cart-btn" onClick={() => handleAddToCart(item)}>Add to Cart</button>
                      </div>
                    ))}
                  </div>
                )}
                {selectedCategory === "Pooja" && poojaCategoryDetails.length > 0 && (
                  <PoojaCard details={poojaCategoryDetails} />
                )}
              </>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
